import Navbar from "../components/nav/Navbar";
import './airqlanding.css';

import headerImage from './img/pc-mock-header.png'
import sensorImage from '../components/featured/sensors-bg.png'
import dashImage from './img/pc-mock-dash.png'
import StayConnected from "../components/stay-connected/StayConnected";
import Services from "../components/services/Services";
import Contact from "../components/Contact";



function AirQLanding(){

    return(
        <>
        <Navbar />

        
        <section className="bg-gray-900">
            <div className="container mx-auto py-24">
                <h1 className="text-5xl text-gray-100 font-semibold text-center">Environmental Metrics</h1>
                <h4 className="text-2xl text-gray-100 font-regular text-center pb-8">with MobileAirQ</h4>
                <img className="w-1/2 pl-2 mx-auto" src={headerImage} />
            </div>
        </section>

        <section className="bg-gray-50">
            <div className="container mx-auto py-24">
                <h1 className="text-4xl text-gray-900 font-semibold text-center">Land Analytics on One Platform</h1>
                <p className="text-xl text-center mt-8 mx-36">Environmental monitoring is a key component for understanding land. Our analytics dashboard and maps helps farmers, developers, and land owners know the real-time environmental conditions of their acreage. Our platform works with all types of environmental data to provide a broad overview and recommendations for soil, water content, and more.</p>
            </div>
        </section>

        <section className="flex flex-row flex-wrap bg-white">
            <div className="mx-auto lg:basis-1/2 sm:basis-full">
                <div className="featured-image bg-cover shrink">
                    <img src={sensorImage} />
                </div>
            </div>
            
            <div className="mx-auto md:basis-1/2 sm:basis-full">
                <div className="basis-1/2 px-16 py-16 text-left">
                    <h5 className="text-sm font-semibold text-gray-600 mb-4">High Fidelity Sensor Network</h5>
                    <h3 className="text-4xl tracking-tight font-semibold text-gray-900 mb-8">Track Conditions with Real-Time Sensors</h3>
                    <p className="text-lg text-gray-800 mb-8">
                    Our low-cost sensors can be placed anywhere to track the current and historical conditions of your land. With readings for Temperature, Relative Humidity, Light Exposure, and Stress Factors, you'll always know the state of your land. 
                    </p>

                    <a href="#contact" class="mt-16 rounded-none bg-neutral-900 p-4 px-16 text-white">
                    Learn More
                    </a>
                </div>
            </div>
            
        </section>

        <section className="bg-gray-50 py-36 flex flex-row flex-wrap">

            <div className="mx-auto basis-1/2 md:basis-1/2 sm:basis-full px-16">
                <h1 className="text-4xl tracking-tight text-gray-900 font-semibold text-left">Easy to Use Interface</h1>
                <p className="text-xl text-left mt-8">Our engineers focus on ease-of-use and making sure your data is where yuo need it. Our platform is always improving to provide a best-in-class experience.</p>
            </div>

            <div className="mx-auto lg:basis-1/2 md:basis-1/2 sm:basis-full">
                <img src={dashImage} />
            </div>
        </section>

        <Contact />
        {/* <StayConnected /> */}
        <Services />
        
       
        </>
    )
}

export default AirQLanding;