// src/components/Projects.js

// import { CodeIcon } from "@heroicons/react/solid";
import React from "react";
//import { projects } from "../../data";
//import mapSvg from "./map-data.svg"
import "./services.css";
import { Waypoint } from 'react-waypoint';


export default function Services() {
  return (
    <section id="services" className="flex flex-row flex-wrap bg-gray-50 body-font md:px-48 sm:px-16 py-8 text-neutral-900">

      <div className="md:basis-2/3 sm:basis-full flex-col w-full px-8">
        <h3 className="mx-auto text-3xl tracking-tight font-semibold mb-8 md:text-left text-center">Our Services</h3>
        <ul className="mb-8 md:text-left text-center">
          <li>Artifical Intelligence and Image Detection</li>
          <li>Crop Information and Management</li>
          <li>Wildfire Detection and Mitigation</li>
          <li>Environmental Impact Research</li>
          <li>Statistical Analysis</li>
          <li>Software Development and Design</li>
        </ul>
      </div>

      <div className="md:basis-1/3 sm:basis-full px-8 text-center md:text-left mx-auto">
        <h3 className="text-3xl tracking-tight font-semibold mb-8">Contact</h3>
        <p>Topologik LLC</p>
        <p>(510) 982-1850</p>
        <p>102 South Hall Rd</p>
        <p>Berkeley, CA, 94720</p>
        <a href="mailto:info@topologik.xyz" className="text-neural-900 underline">
          info@topologik.xyz
        </a>
      </div>
    </section>
  );
}