import React from 'react';
// import formSuccess from '../form-success/formSuccess'


export default function ContactForm() {
    
    return (
    <form className="w-full mx-auto" name="contactForm" method="POST" >
            <div className="flex md:flex-row md:flex-wrap flex-wrap content-center gap-y-4">
            <input type="hidden" name="form-name" value="contactForm" />
            <input className="bg-white w-full text-gray-700  mb-4 md:mb-0 py-4 px-8 focus:outline-none" type="text" name="name" placeholder="Name" aria-label="Name"/>
            <input className="bg-white w-full text-gray-700  mb-4 md:mb-0 py-4 px-8 focus:outline-none" type="email" name="email" placeholder="Enter Email Address" aria-label="Email Address"/>
            <textarea className="bg-white w-full text-gray-700  mb-4 md:mb-0 py-4 pb-16 px-8 focus:outline-none" type="text" name="message" placeholder="Message" aria-label="Message"></textarea>
            <input className="w-full bg-white hover:bg-gray-300 font-bold flex-shrink text-neutral-900 py-4" type="Submit" value="Submit" />
            </div>
    </form>
    );

}