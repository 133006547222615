import React from "react";
import ContactForm from "./contact-form/ContactForm";

export default function Contact() {
  return (
    <section id="sec-stay-connected" className="bg-gray-900">
      <div className="container px-48 py-10 mx-auto flex flex-col">
        <h1 className="text-4xl font-semibold text-gray-50 text-center pb-8">Contact Us to Learn More</h1>
        <ContactForm />
      </div>

      
    </section>
  );
}